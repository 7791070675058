import Vue from 'vue'
import App from '@/App.vue'

import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'

import vco from "v-click-outside"

import router from './router'
import store from '@/state/store'
import VueCookies from 'vue-cookies'
import "@/assets/scss/_app.scss";
import axios from "axios";
import VueCountryCode from "vue-country-code";
import VueHtml2pdf from 'vue-html2pdf'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import Child from "@/components/category/child.vue";
import Parent from "@/components/category/parent.vue";
import Multiselect from "vue-multiselect";
import EcatMultiselect from "@/components/ecat-multiselect.vue";
import InfiniteLoading from 'vue-infinite-loading';
import {translationHelper} from "@/helpers/translation-helper";
import VueGallery from 'vue-gallery'
import EcatFormSelect from "@/components/ecat-form-select.vue";
import VueCodeHighlight from 'vue-code-highlight';
import 'vue-code-highlight/themes/prism-tomorrow.css'
import 'prism-es6/components/prism-yaml'
import LoadScript from 'vue-plugin-load-script';
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import ecatButton from "@/components/ecat-button.vue";
import VueAppleLogin from 'vue-apple-login';
import ecatTable from "./components/ecat-table.vue";
import OnlyBeta from "@/components/OnlyBeta.vue";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import EcatInput from "@/components/ecat-input.vue";
import FlagIcon from 'vue-flag-icon'
import cardBase from "@/components/card-base.vue";
import cardTextCentered from "@/components/card-text-centered.vue";
import card from "@/components/card.vue";

Vue.config.productionTip = process.env.NODE_ENV === 'test'

axios.defaults.withCredentials = true;
axios.defaults.timeout = process.env.AXIOS_TIMEOUT
axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.interceptors.response.use(
//     (resp) => {
//       let fe_version = resp.headers['fe-version'] || 'default'
//       if(fe_version !== localStorage.getItem('fe-version') && resp.config.method === 'get'){
//         localStorage.setItem('fe-version', fe_version)
//         window.location.reload() // For new version, simply reload on any get
//       }
//
//       return Promise.resolve(resp)
//     }
// )

Vue.use(VueCookies)
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueCountryCode)
Vue.use(VueHtml2pdf)
Vue.use(FlagIcon);

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

Vue.use(pinia)
Vue.use(InfiniteLoading)
Vue.use(LoadScript)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(VueAppleLogin, {
    clientId: 'pl.ecat.dropshipping',
    scope: 'name email',
    redirectURI: `${process.env.VUE_APP_API_URL}/user/oauth2-integration/icloud?environment=${process.env.VUE_APP_ECAT_ENVIRONMENT_TYPE}`,
    state: "Initial user authentication request",
    usePopup: false,
});

Vue.use(VueCodeHighlight)
Vue.component("apexchart", VueApexCharts)
Vue.component("category-child", Child)
Vue.component("category-parent", Parent)
Vue.component("vue-multiselect", Multiselect)
Vue.component("ecat-multiselect", EcatMultiselect)
Vue.component("ecat-form-select", EcatFormSelect)
Vue.component("OnlyBeta", OnlyBeta)
Vue.component("VGallery", VueGallery)
Vue.component("Cropper", Cropper)
Vue.component("ecat-button", ecatButton)
Vue.component("ecat-table", ecatTable)
Vue.component("ecat-input", EcatInput)
Vue.component("CustomCardSubtitle", CustomCardSubtitle)
Vue.component("card-base", cardBase)
Vue.component("card-text-centered", cardTextCentered)
Vue.component("card", card)

// eslint-disable-next-line no-unused-vars
Vue.prototype.$t = function(translation, ownText = "") {
    return translationHelper.t(translation, {})
}

// eslint-disable-next-line no-unused-vars
Vue.prototype.$t = function(translation, options = {}, ownText = "") {
  return translationHelper.t(translation, options)
}

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default app;