<template>
  <div v-if="user && user.allowedOnBetaEnvironment && $store.getters['beta/getOnlyBeta']">
    <slot />
  </div>
</template>
<script>
import {computed} from "vue";
import {useUserStore} from "@/store/user";

export default {
  setup() {
    const userStore = useUserStore();
    return {
      user: computed(() => userStore.getUser)
    }
  }
}
</script>