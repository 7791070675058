<template>
  <ul>
    <template v-for="(tree, index) in trees">
      <li :key="index">
        <details @click="ecatClickCategory(tree)">
          <summary style="z-index: 1;"> {{ tree.name }} {{ showProductCount ? '(' + $t('categories.product-count', { "count": tree.productCount, "profitMargin": tree.profitMargin}) + ')' : "" }} <a style="z-index: 999;" @click="ecatClickEdit(tree)" href="#" class="text-primary"><i class="mdi mdi-pencil font-size-18"></i></a> <a style="z-index: 999;" @click="ecatClickDelete(tree)" href="#" class="text-danger"><i class="mdi mdi-trash-can font-size-18"></i></a></summary>
          <category-child v-if="tree.categories" :show-product-count="showProductCount" :children="tree.categories"></category-child>
        </details>
      </li>
    </template>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      fn: null
    }
  },

  props: {
    trees: [],
    showProductCount: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    ecatClickCategory(child) {
      this.$root.$emit('ecat-click-category', child)
    },

    ecatClickEdit(child) {
      this.$root.$emit('ecat-click-edit', child)
    },

    ecatClickDelete(child) {
      this.$root.$emit('ecat-click-delete', child)
    }
  },

  mounted() {
    this.fn = () => {
      this.$forceUpdate()
    }

    this.$root.$on('refresh-tree', this.fn)
  },

  beforeDestroy() {
    this.$root.$off('refresh-tree', this.fn)
  }

}
</script>