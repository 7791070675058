<template>
  <div class="bank-group d-flex flex-column align-items-center justify-content-center text-center" :class="currentBankGroup && currentBankGroup.id === bankGroup.id ? 'active-bank-group' : ''" @click="$emit('click')">
    <div class="mb-4">
      <img :src="bankGroup.imageUrl" alt="Image" height="36">
    </div>

    <p>{{ bankGroup.name }}</p>
  </div>
</template>

<script>
export default {

  props: {
    bankGroup: {
      type: Object,
      required: true
    },

    currentBankGroup: {
      type: Object,
      default: null
    }
  }

}
</script>

<style scoped>
.bank-group {
  width: 10rem;
  height: 10rem;
  font-size: 14px;
  margin: 10px;
  border: 2px solid var(--gray-400);
  background-color: var(--gray-300);
  display: flex;
  cursor: pointer;
}

.active-bank-group {
  background: #18a689;
  border: #18a689;
  color: white;
}
</style>