export const state = () => ({
    e: 0,
    p: 0
})

export const getters = {
    getE: state => state.e,
    getP: state => state.p
}

export const mutations = {
    SET_E(state, payload) {
        state.e = payload
    },

    SET_P(state, payload) {
        state.p = payload
    }
}

export const actions = {
    setE({ commit }, payload) {
        commit("SET_E", payload)
    },

    setP({ commit }, payload) {
        commit("SET_P", payload)
    },
}