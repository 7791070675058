export const state = () => ({
    tagSetups: new Map(),
    tagSetupsByIndex: new Map(),
    readyTagSetups: new Map()
})

export const getters = {
    hasAnyTag: state => (name) => {
        if (!state.tagSetups) {
            return false
        }

        return Array.from(state.tagSetups.values())
            .some(obj => obj.matchedTags.some(item => item.name === name));
    },

    getTagSetupsByIndex: state => state.tagSetupsByIndex,
    getTagSetups: state => state.tagSetups,
    getTagSetupByIndex: state => (index) => state.tagSetupsByIndex.get(index),
    getReadyTagSetups: state => state.readyTagSetups
}

export const mutations = {
    CLEAR_TAG_SETUPS(state) {
        state.tagSetups = null
    },

    ADD_TAG_SETUP_OR_UPDATE(state, payload) {
        if (!state.tagSetups) {
            state.tagSetups = new Map();
        }

        if (!state.tagSetupsByIndex) {
            state.tagSetupsByIndex = new Map();
        }

        const { index, xmlUrl, tagSetup } = payload;

        state.tagSetups.set(xmlUrl, tagSetup)
        state.tagSetupsByIndex.set(index, tagSetup)
    },

    SET_READY(state, payload) {
        const { index, ready } = payload;
        // console.log(index, ready)
        state.readyTagSetups.set(index, ready)
    },

    CLEAR_READY(state) {
        state.clearReady = new Map()
    }
}

export const actions = {
    clearTagSetups({commit}) {
        commit('CLEAR_TAG_SETUPS')
    },

    addTagSetupOrUpdate({commit}, payload) {
        commit('ADD_TAG_SETUP_OR_UPDATE', payload)
    },

    setReady({commit}, payload) {
        commit('SET_READY', payload)
    },

    clearReady({commit}) {
        commit("CLEAR_READY")
    }
}