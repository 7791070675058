export const state = () => ({
    oldSession: {},
    newSession: {}
})

export const getters = {
    getOldSession: (state) => state.oldSession,
    hasSession: (state) => state.newSession && state.newSession.sessionId,
    getNewSession: (state) => state.newSession
}

export const mutations = {
    SET_SESSIONS(state, payload) {
        const { oldSession, newSession } = payload
        state.oldSession = oldSession;
        state.newSession = newSession
    },

    CLEAR_SESSIONS(state) {
        state.oldSession = {}
        state.newSession = {}
    }
}

export const actions = {
    setSessions({commit}, payload) {
        commit("SET_SESSIONS", payload)
    },

    clearSessions({commit}, payload) {
        commit("CLEAR_SESSIONS", payload)
    }
}