export const state = () => ({
    languages: [],
    translations: {},
    translationsFallback: {},
    currentLocale: "6225bd79-386d-4358-af12-d4419d3aad62"
})

export const getters = {
    getTranslation: state => (i18N, options = {}) => {
        const translation = state.translations[i18N]
        if (translation) {
            return getters.replaceVariables(translation, options);
        }

        const fallbackTranslation = state.translationsFallback[i18N]
        if (fallbackTranslation) {
            return getters.replaceVariables(fallbackTranslation, options);
        }

        return getters.replaceVariables(i18N, options);
    },

    replaceVariables(translation, args) {
        for (const key in args) {
            // eslint-disable-next-line no-prototype-builtins
            if (args.hasOwnProperty(key)) {
                const regex = new RegExp(`{${key}}`, 'g');
                translation = translation.replace(regex, args[key]);
            }
        }

        return translation;
    },

    getCurrentLocale: state => state.currentLocale,
    getLanguages: state => state.languages,
    getLanguageById: state => (id) => state.languages.find(element => element.id === id),
    getCurrentLocaleOrFallback: state => {
        const language = getters.getLanguageById(state)(state.currentLocale)
        if (!language) {
            return "en"
        }

        return language.countryCodeISO639_1
    },

    getCurrentLanguage: state => getters.getLanguageById(state)(state.currentLocale)
}

export const mutations = {
    SET_TRANSLATIONS(state, payload) {
        state.translations = payload;
    },

    SET_TRANSLATIONS_FALLBACK(state, payload) {
        state.translationsFallback = payload;
    },

    SET_LANGUAGES(state, payload) {
        state.languages = payload
    },

    SET_CURRENT_LOCALE(state, payload) {
        state.currentLocale = payload
    }
}

export const actions = {
    setTranslations({commit}, payload) {
        commit('SET_TRANSLATIONS', payload)
    },

    setTranslationsFallback({commit}, payload) {
        commit('SET_TRANSLATIONS_FALLBACK', payload)
    },

    setLanguages({commit}, payload){
        commit('SET_LANGUAGES', payload)
    },

    setCurrentLocale({commit}, payload) {
        commit('SET_CURRENT_LOCALE', payload)
    }
}