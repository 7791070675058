export const languageHelper = {
    transformJSONToMap,
    transformMapToJSON,
};

function transformJSONToMap(data, prefix = '') {
    const result = {};
    for (const key in data) {
        if (typeof data[key] === 'object') {
            const newPrefix = prefix ? `${prefix}.${key}` : key;
            Object.assign(result, this.transformJSONToMap(data[key], newPrefix));
        } else {
            result[prefix ? `${prefix}.${key}` : key] = data[key];
        }
    }

    return result;
}

function transformMapToJSON(data) {
    const result = {};

    for (const [key, value] of data) {
        const keys = key.split('.');
        let currentObj = result;

        for (let i = 0; i < keys.length; i++) {
            const k = keys[i];
            if (i === keys.length - 1) {
                currentObj[k] = value;
            } else {
                if (!currentObj[k]) {
                    currentObj[k] = {};
                }
                currentObj = currentObj[k];
            }
        }
    }

    return result;
}