export const state = () => ({
    productIds: [],
    generateName: false,
    generateDescription: false
})

export const getters = {
    getProductIds: state => state.productIds,
    shouldGenerateName: state => state.generateName,
    shouldGenerateDescription: state => state.generateDescription
}

export const mutations = {
    SET_DATA(state, payload) {
        const { productIds, generateName, generateDescription } = payload;

        state.productIds = productIds
        state.generateName = generateName
        state.generateDescription = generateDescription
    }
}

export const actions = {
    setData({commit}, payload) {
        commit('SET_DATA', payload)
    }
}