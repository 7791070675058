import { defineStore } from 'pinia'

export const useUserStore = defineStore({
  id: "user",
  persist: true,

  state: () => {
    return { 
        user: null,
    }
  },
  actions: {
    modifyUser(_user) {
        this.user = _user
    },
  },
  getters: {
    getUser: (state) => state.user,
  }
})