<template>
  <b-modal
      v-if="internalOrder"
      v-model="visibleModal"
      :title="internalOrder.title"
      size="xl"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="form-group">
      <p>Do zapłaty: {{ priceHelper.format(internalOrder.price, $store.getters["market/currentCurrency"]) }}</p>
    </div>
    <div class="form-group">
      <div class="row">
        <div v-for="(bankGroup, index) in bankGroups" :key="index" class="col-xl-2">
          <bank-group :bank-group="bankGroup" :current-bank-group="currentBankGroup" @click="handleClick(bankGroup)" />
        </div>
      </div>
    </div>

    <div class="text-center">
      <ecat-button :callback="pay" variant="success" message="Zapłać" />
      <b-button class="ml-1" variant="danger" @click="hideModal">{{ $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import {errorCatcher} from "../../helpers/error-catcher";
import axios from "axios";
import BankGroup from "./bank-group.vue";
import {priceHelper} from "@/helpers/price-helper";
import Swal from "sweetalert2";

export default {
  components: {BankGroup},

  data() {
    return {
      visibleModal: false,
      internalOrder: "",
      bankGroups: [],
      currentBankGroup: null
    }
  },

  computed: {
    priceHelper() {
      return priceHelper
    }
  },

  methods: {

    openModal(internalOrder) {
      if (internalOrder.gatewayType !== "TPAY") {
        Swal.fire("Błąd!", "Bramka płatności nie zaimplementowana!", "error");
        return
      }

      this.internalOrder = internalOrder
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.internalOrder = null
    },

    handleClick(bankGroup) {
      this.currentBankGroup = bankGroup
    },

    async loadBankGroups() {
      try {
        const { data } = await axios.get(`/tpay/bank-groups`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.bankGroups = data.data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async pay() {
      if (!this.currentBankGroup) {
        await Swal.fire("Błąd!", "Wybierz metodę płatności!", "error");
        return
      }

      try {
        const json = {
          internalOrderId: this.internalOrder.id,
          payGroupId: this.currentBankGroup.id,
          environmentType: process.env.VUE_APP_ECAT_ENVIRONMENT_TYPE
        }

        const {data} = await axios.post(`/tpay`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        window.location = data.transactionPaymentUrl
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  },

  async created() {
    await this.loadBankGroups()
  }
}
</script>