import router from "@/router";

export const routerHelper = {
    push,
    params
};

function push(path, query) {
    let tryAgain = true;

    router.push({path: path, query: query}).catch(err => {
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
            console.log(err)
        }

        if (tryAgain) {
            router.push({path: path, query: query}).catch(err => {
                if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
                    console.log(err)
                }

                tryAgain = false;
            });
        }
    });
}

function params() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    return Object.fromEntries(urlSearchParams.entries());
}