import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';

import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
      createPersistedState({
        paths: [ "theme", "shop", "market", "role", "multiselect", "translation", "aiproductsgenerate", "stripe", "wordpressnotactivated", "formselect", "parameters", "storelimits", "allegro", "engine", "custompath", "simulatesession", "beta", "lastclick" ]
      })
  ],
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store