import store from "../state/store";

export const priceHelper = {
    format
};

function format(value, currency) {
    if (!currency) {
        currency = "PLN"
    }

    if (!value) {
        value = 0
    }

    return value.toLocaleString(store.getters["translation/getCurrentLocaleOrFallback"], { style: 'currency', currency: currency });
}