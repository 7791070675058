export const state = () => ({
    multiSelects: []
})

export const getters = {
    getMultiselect: state => (multiSelectId) => {
        if (!state.multiSelects || !multiSelectId) {
            return null
        }

        return state.multiSelects.find(item => item.id === multiSelectId) || null
    }
}

export const mutations = {
    SET_MULTISELECT(state, payload) {
        const { id, value } = payload;
        const existingItem = state.multiSelects.find(item => item.id === id);

        if (existingItem) {
            existingItem.value = value;
        } else {
            state.multiSelects.push({ id, value });
        }
    }
}

export const actions = {
    setMultiselect({ commit }, payload) {
        commit('SET_MULTISELECT', payload)
    }
}