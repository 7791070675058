<template>
  <div class="w-100">
    <label v-if="label">{{ label }}</label>

    <div class="input-container" :class="disabled ? 'input-disabled' : ''">
      <span v-if="internalValue && !hideIcon" class="clear-icon">
        <span class="clickable-element text-danger font-size-14" @click="reset">
          <i class="fa fa-times"/>
        </span>
    </span>

      <input
          :id="id"
          v-model="internalValue"
          :class="internalValue && !hideIcon ? 'input-selected input-field' : '' + inputClass ? inputClass : 'mb-2'"
          :type="type"
          :placeholder="placeholder"
          class="form-control"
          @input="handleInput"
          @keyup.enter="$emit('should-reload')"
          :disabled="disabled"
          :required="required"
          :minlength="minLength"
          :maxlength="maxLength"
      />
    </div>

  </div>
</template>
<script>

import {debounce} from "vue2-leaflet/dist/vue2-leaflet.min";

export default {
  name: "ecat-input",

  props: {
    value: {
      validator: function (value) {
        return value === null || value instanceof Date || typeof value === 'string' || typeof value === 'number'
      }
    },

    minLength: {
      type: Number,
      required: false
    },

    maxLength: {
      type: Number,
      default: Number.MAX_SAFE_INTEGER
    },

    label: {
      type: String
    },

    placeholder: {
      type: String,
      default: ""
    },

    id: {
      type: String
    },

    type: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },

    hideIcon: {
      type: Boolean,
      default: false
    },

    inputClass: {
      type: String
    }
  },

  watch: {
    internalValue: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.$emit("input", newValue);
        }
      }
    },

    value: {
      deep: true,
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.internalValue = newValue
        }
      }
    },
  },

  data() {
    return {
      internalValue: this.value
    }
  },

  methods: {
    handleInput: debounce(function () {
      this.$emit('should-reload')
    }, 250),

    reset() {
      this.$emit('input')
      this.$emit('should-reload')
    }
  }

}
</script>

<style scoped>
.input-container {
  position: relative;
}

.input-field {
  padding-left: 25px;
}

.clear-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
</style>