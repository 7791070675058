export const state = () => ({
    publishableKey: ""
})

export const getters = {
    publishableKey: state => {
        return state.publishableKey
    }
}

export const mutations = {
    SET_PUBLISHABLE_KEY(state, publishableKey) {
        state.publishableKey = publishableKey;
    }
}

export const actions = {
    setPublishableKey({commit}, payload) {
        commit('SET_PUBLISHABLE_KEY', payload)
    }
}