export const state = () => ({
    customPath: null,
})

export const getters = {
    getCustomPath: state => state.customPath
}

export const mutations = {
    SET_CUSTOM_PATH(state, payload) {
        state.customPath = payload
    },

    CLEAR_CUSTOM_PATH(state) {
        state.customPath = null
    }
}

export const actions = {
    setCustomPath({commit}, payload) {
        commit("SET_CUSTOM_PATH", payload)
    },

    clearCustomPath({commit}, payload) {
        commit("CLEAR_CUSTOM_PATH", payload)
    }
}