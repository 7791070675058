import {translationHelper} from "@/helpers/translation-helper";

export const errorCatcher = {
    catchErrors,
    isCustomError,
    getError
};

import { BToast } from 'bootstrap-vue'
import router from '@/router'
import {routerHelper} from "@/helpers/router-helper";
import app from '@/main.js';

const errors = new Map([
    [ "AuthenticationErrors", new Map([
        // [0, "error.authentication-errors.token-not-found"],
        // [1, "error.authentication-errors.user-not-found"]
        // [2, "error.authentication-errors.not-verified-phone-number"],
        // [3, "error.authentication-errors.not-verified-email-address"],
    ])
    ],

    [ "UserSessionErrors", new Map([
        [0, "session.expired"],
        [1, "error.user-errors.bad-email-or-password"],
        [2, "error.user-errors.invalid-data-structure"],
        [3, "error.session-errors.not-authorized"],
        [4, "error.session-errors.authorization-code-expired"],
        [5, "error.session-errors.invalid-authorization-code"]
    ])
    ],

    [ "UserVerificationErrors", new Map([
        [1, "verify.error.code-expired"],
        [2, "verify.error.invalid-code"],
        [3, "verify.error.already-verified"],
    ])
    ],

    [ "UserErrors", new Map([
        [0, "error.user-errors.user-not-found"],
        [1, "error.user-errors.invalid-data-structure"],
        [2, "error.user-errors.already-exists"],
        [3, "message.email-error"],
        [4, "error.user-errors.invalid-password"],
        [5, "message.number-error"],
        [6, "error.user-errors.invalid-old-password"],
        [7, 'error.user-errors.registration-access-restricted'],
        [8, "error.user-errors.invalid-verification-step-to-perform-this-action"],
        [9, "error.user-errors.unknown-oauth2-login-error"]
    ])
    ],

    [ "ShopErrors", new Map([
        [0, "error.shop-errors.user-not-found"],
        [1, "error.shop-errors.shop-not-found"],
        [2, "error.shop-errors.shop-not-owned-by-user"],
        [3, "error.shop-errors.invalid-data-structure"],
        [4, "error.shop-errors.invalid-email"],
        [5, "error.shop-errors.invalid-domain"],
        [7, "error.shop-errors.shop-with-this-domain-already-exists"],
        [8, "error.shop-errors.shop-limit-exceeded"]
    ])
    ],

    [ "WholesalerRegistrationErrors", new Map([
        [0, "error.wholesaler-registration-errors.form-not-found"],
        [1, "wholesaler-management.register.same-form-status"],
        [2, "error.invalid-data-structure"],
        [3, "error.wholesaler-registration-errors.company-name-too-long"],
        [4, "error.wholesaler-registration-errors.company-address-too-long"],
        [5, "wholesaler-management.register.invalid-tax"],
        [6, "error.wholesaler-registration-errors.website-too-long"],
        [7, "error.wholesaler-registration-errors.extra-content-too-long"],
        [8, "message.number-error"],
        [9, "message.email-error"],
        [10, "error.wholesaler-registration-errors.industry-not-found"],
        [11, "error.wholesaler-registration-errors.carriers-not-found"],
        [12, "error.wholesaler-registration-errors.market-not-found"],
        [13, "error.wholesaler-registration-errors.cannot-edit-carriers-for-accepted-form"],
        [14, "error.wholesaler-registration-errors.referral-code-too-long"],
    ])
    ],

    [ "SmsErrors", new Map([
        [0, "error.sms-errors.send-error"]
    ])
    ],

    [ "WarehouseErrors", new Map([
        [0, "error.warehouse-errors.warehouse-not-found"]
    ])
    ],

    [ "UserCompanyErrors", new Map([
        [0, "error.user-company-errors.user-not-found"],
        [1, "error.user-company-errors.company-not-found"],
        [2, "error.user-company-errors.company-not-owned-by-user"],
        [3, "error.user-company-errors.invalid-tax-number"]
    ])
    ],

    [ "ParserErrors", new Map([
        [0, "error.parser-errors.tag-name-too-long"],
        [1, "error.parser-errors.too-many-attributes"],
        [2, "error.parser-errors.refresh-frequency-too-low"],
        [3, "error.parser-errors.parser-id-not-provided"]
    ])
    ],

    [ "CategoryErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.category-errors.category-not-found"],
        [2, "error.category-errors.parent-cannot-be-same-as-current-category"],
    ])
    ],

    [ "ShopCategoryErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.shop-category-errors.related-category-already-exists"],
        [2, "error.shop-category-errors.shop-category-not-found"],
        [3, "error.shop-category-errors.shop-mismatch"],
        [4, "error.shop-category-errors.parent-cannot-be-same-as-current-category"]
    ])
    ],

    [ "NewsErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.news-errors.news-not-found"],
        [2, "error.news-errors.same-status"]
    ])
    ],

    [ "AllegroAccountErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.allegro-errors.active-account-not-found"],
        [2, "error.allegro-errors.allegro-internal-error"],
        [3, "error.allegro-errors.invalid-allegro-token"],
        [4, "error.allegro-errors.invalid-username"],
        [5, "error.allegro-errors.invalid-tax-number"],
        [6, "error.allegro-errors.invalid-client-id"],
        [7, "error.allegro-errors.invalid-client-secret"],
        [8, "error.allegro-errors.account-not-found"],
        [9, "error.allegro-errors.allegro-company-not-set"],
        [10, "error.allegro-errors.return-policy-not-found"],
        [11, "error.allegro-errors.shipping-table-not-found"],
        [12, "error.allegro-errors.warranty-not-found"],
        [13, "error.allegro-errors.implied-warranty-not-found"],
        [14, "error.allegro-errors.shipping-rate-data-not-found"],
        [15, "error.allegro-errors.account-already-exists"],
        [16, "error.allegro-errors.too-many-accounts-for-one-shop"]
    ])
    ],

    [ "InstructionErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.instruction-errors.instruction-not-found"],
    ])
    ],

    [ "InstructionCategoryErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.instruction-category-errors.instruction-category-not-found"],
    ])
    ],

    [ "ShortCodeErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.short-code-errors.short-code-not-found"],
    ])
    ],

    [ "ParserBannedPhraseErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.parser-banned-phrase-errors.banned-phrase-not-found"],
    ])
    ],

    [ "InvestorErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.investor-errors.investor-not-found"],
    ])
    ],

    [ "ShopProductErrors", new Map([
        [0, "error.shop-product-errors.user-not-found"],
        [1, "error.shop-product-errors.shop-product-count-unknown-error"],
        [2, "error.shop-product-errors.product-count-unknown-error"],
        [3, "error.shop-product-errors.product-not-found"],
        [4, "error.shop-product-errors.product-already-exists"],
        [5, "error.shop-product-errors.allegro-account-not-found"],
        [6, "error.shop-product-errors.allegro-account-not-active"],
        [7, "error.shop-product-errors.product-count-is-zero"],
        [8, "error.shop-product-errors.shop-product-limit-exceeded"],
        [9, "error.shop-product-errors.price-increase-percent-cannot-be-lower-than-0"],
        [10, "error.shop-product-errors.price-increase-value-cannot-be-lower-than-0"],
        [11, "error.shop-product-errors.profit-lowered-by-percent-cannot-be-lower-than-0"]
    ])
    ],


[ "AllegroAuctionErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.allegro-auction-errors.product-not-found"],
        [2, "error.allegro-auction-errors.shop-not-found"],
        [3, "error.allegro-auction-errors.shop-product-not-found"],
        [4, "error.allegro-auction-errors.auction-not-found"],
    ])
    ],

    [ "ShopOrderErrors", new Map([
        [0, "error.shop-order-errors.not-found-carrier"],
        [1, "error.shop-order-errors.shop-order-not-found"],
        [2, "error.shop-order-errors.metadata-parse-error"],
        [3, "error.shop-order-errors.profit-calculation-error"],
    ])
    ],

    [ "DepotErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.depot-errors.depot-not-found"],
        [2, "error.depot-errors.product-not-found"],
        [3, "error.depot-errors.product-variations-not-found"],
        [4, "error.depot-errors.product-id-is-null"],
        [5, "error.depot-errors.depot-with-that-warehouse-id-already-exists"],
        [6, "error.depot-errors.product-already-duplicated"]
    ])
    ],

    [ "DepotSectionErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.depot-section-errors.depot-section-not-found"],
        [2, "error.depot-section-errors.product-not-found"],
        [3, "error.depot-section-errors.product-variations-not-found"],
        [4, "error.depot-section-errors.product-id-is-null"],
        [5, "error.depot-section-errors.depot-with-that-warehouse-id-already-exists"],
        [6, "error.depot-section-errors.product-already-duplicated"],
        [7, "error.depot-section-errors.depot-not-found"]
    ])
    ],

    [ "DepotAvailableStockErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.depot-available-stock-errors.depot-available-stock-not-found"],
        [2, "error.depot-available-stock-errors.not-enough-stock-in-depot"]
    ])
    ],

    [ "RegistrationAccessErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.registration-access-errors.access-entity-not-found"],
        [2, "error.registration-access-errors.access-times-not-set-for-specified-date"]
    ])
    ],

    [ "WarehouseCarrierErrors", new Map([
        [0, "error.warehouse-carrier-errors.warehouse-not-found"],
        [1, "error.warehouse-carrier-errors.not-warehouse-owner"],
        [2, "error.warehouse-carrier-errors.carrier-not-found"],
        [3, "error.invalid-data-structure"],
        [4, "error.warehouse-carrier-errors.oversize-carrier-already-exists"],
        [5, "error.warehouse-carrier-errors.action-forbidden"],
        [6, "error.warehouse-carrier-errors.oversize-cannot-be-set-to-type-smart"],
        [7, "error.warehouse-carrier-errors.shipping-cost-cannot-be-lower-than-allegro-first-item-price-min"],
        [8, "error.warehouse-carrier-errors.shipping-cost-cannot-be-greater-than-allegro-first-item-price-min"]
    ])
    ],

    [ "GlobalCarrierErrors", new Map([
        [0, "error.global-carrier-errors.market-not-found"],
        [1, "error.global-carrier-errors.carrier-not-found"],
        [2, "error.invalid-data-structure"],
        [3, "error.global-carrier-errors.oversize-carrier-already-exists"],
        [4, "error.global-carrier-errors.action-forbidden"],
        [5, "error.global-carrier-errors.oversize-cannot-be-set-to-that-type"],
        [6, "error.global-carrier-errors.shipping-cost-cannot-be-lower-than-allegro-first-item-price-min"],
        [7, "error.global-carrier-errors.shipping-cost-cannot-be-greater-than-allegro-first-item-price-min"]
    ])
    ],

    [ "InformationBarErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.information-bar-errors.message-exceeded-255-length-limit"],
        [2, "error.information-bar-errors.start-time-is-after-end-time"],
        [3, "error.information-bar-errors.end-time-is-before-start-time"],
        [4, "error.information-bar-errors.invalid-background-hex-color"],
        [5, "error.information-bar-errors.invalid-text-hex-color"],
        [6, "error.information-bar-errors.order-value-field-has-to-be-greater-than-0"],
    ])
    ],

    [ "HelpVideoErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.help-video-errors.language-not-found"],
        [2, "error.help-video-errors.help-video-already-exists-for-that-language-and-path"],
        [3, "error.help-video-errors.path-exceeded-255-length-limit"],
        [4, "error.help-video-errors.video-id-exceeded-255-length-limit"],
        [5, "error.help-video-errors.help-video-not-found"],
        [6, "error.help-video-errors.help-video-already-deleted"],
    ])
    ],

    [ "ReCaptchaErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.recaptcha-errors.captcha-key-is-invalid"],
    ])
    ],

    [ "User2FAErrors", new Map([
        [0, "error.invalid-data-structure"],
        [1, "error.user-2fa-errors.invalid-otp-code"],
        [2, "error.user-2fa-errors.qr-code-not-generated-yet"],
        [3, "error.user-2fa-errors.session-not-found"],
        [4, "error.user-2fa-errors.two-factor-auth-not-enabled"],
    ])
    ],

    [ "ShippingErrors", new Map([
        [0, "error.shipping-errors.standard-carrier-price-not-found"],
        [1, "error.shipping-errors.carrier-not-found"],
        [2, "error.shipping-errors.product-not-found"],
        [3, "error.shipping-errors.warehouse-carrier-not-found"],
        [4, "error.shipping-errors.warehouse-not-found"],
        [5, "error.shipping-errors.product-internal-identification-not-found"],
        [6, "error.shipping-errors.allegro-account-not-found"],
        [7, "error.shipping-errors.carriers-for-product-not-set"],
        [8, "error.shipping-errors.oversize-carrier-not-found"],
        [9, "error.shipping-errors.allegro-carriers-not-found"],
        [10, "error.shipping-errors.carriers-not-found"],
    ])
    ],

    [ "CDNErrors", new Map([
        [0, "error.cdn-errors.could-not-upload-file"],
        [1, "error.cdn-errors.not-an-image"],
        [2, "error.cdn-errors.could-not-determine-image-format"],
        [3, "error.cdn-errors.invalid-image-format"],
        [4, "error.cdn-errors.object-not-in-pdf-format"],
    ])
    ],


    [ "UserCryptocurrencyTransactionErrors", new Map([
        [6, "error.user-crypto-currency-transaction-errors.couldnt-estimate-gas-fee"],
        [7, "error.user-crypto-currency-transaction-errors.transfer-amount-exceeds-balance"],
        [8, "error.user-crypto-currency-transaction-errors.transfer-amount-too-high-to-cover-gas-fee"],
        [9, "error.user-crypto-currency-transaction-errors.couldnt-transfer-funds-to-the-same-address"]
    ])
    ]

]);

const ignoredErrors = new Map([
    [ "AuthenticationErrors", [0, 1, 2, 3] ]
]);


function redirect() {
    router.push("/").catch(err => {
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
            console.log(err)
        }
    });
}

function catchErrors(error) {
    if (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development") {
        console.log(error)
    }

    let messageError = error.message;
    if (error.response) {
        if (error.response.status === 502) {
            routerHelper.push("/maintenance")
            return ""
        }

        const ecatError = getError(error)
        if (ecatError) {
            if (ecatError.type === "AuthenticationErrors" && ecatError.code === 4) {
                if (app) {
                    app.$root.$emit("ecat-authorize-2fa-enable-modal")
                }
            }
        }

        try {
            const json = JSON.parse(JSON.stringify(error.response.data));
            if (json !== null) {
                const error = json.error;

                if (error !== null) {
                    const type = error.type;
                    const errorCode = error.code;

                    if ('type' in error && 'code' in error) {
                        if (type === 'UserVerificationErrors' && errorCode === 3) {
                            setTimeout(() => {redirect()}, 450)
                        }

                        const mapErrors = errors.get(type)
                        if (mapErrors) {
                            messageError = translationHelper.t(mapErrors.get(errorCode))
                        } else if (error.message) {
                            messageError = error.message
                        } else {
                            messageError = `${type} - ${errorCode}`
                        }

                        const mapIgnoredErrors = ignoredErrors.get(type)
                        if (mapIgnoredErrors && mapIgnoredErrors.includes(errorCode)) {
                            return;
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    let bootStrapToaster = new BToast();
    bootStrapToaster.$bvToast.toast(messageError, {
        title: translationHelper.t('message.error'),
        variant: 'danger',
        solid: true
    });

    return messageError
}

function isCustomError(error) {
    let custom = true

    if (error.response) {
        try {
            const json = JSON.parse(JSON.stringify(error.response.data));
            if (json !== null) {
                const error = json.error;

                if (error !== null) {
                    const type = error.type;
                    const errorCode = error.code;

                    if ('type' in error && 'code' in error) {
                        if (type === 'UserVerificationErrors' && errorCode === 3) {
                            setTimeout(() => {redirect()}, 450)
                        }

                        const mapErrors = errors.get(type)
                        if (mapErrors) {
                            custom = false
                        }

                        const mapIgnoredErrors = ignoredErrors.get(type)
                        if (mapIgnoredErrors && mapIgnoredErrors.includes(errorCode)) {
                            custom = false
                        }
                    }
                }
            }
        } catch (error) {
            custom = true
        }
    }

    return custom
}

function getError(error) {
    if (error.response) {
        try {
            const json = JSON.parse(JSON.stringify(error.response.data));
            if (json !== null) {
                const error = json.error;

                if (error !== null) {
                    if ('type' in error && 'code' in error) {
                        const type = error.type;
                        const errorCode = error.code;

                        return {type: type, code: errorCode, message: error.message}
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    return null
}