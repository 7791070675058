export const state = () => ({
    operatorProfitDivision: 0,
    ecatProfitDivision: 0,
    aderloShopId: "",
    reCaptchaSiteKey: "",
    allegroSendMinPrice: 0,
    languageFallbackId: "",
    analytics: null
})

export const getters = {
    getOperatorProfitDivision: state => state.operatorProfitDivision,
    getEcatProfitDivision: state => state.ecatProfitDivision,
    getAderloShopId: state => state.aderloShopId,
    getReCaptchaSiteKey: state => state.reCaptchaSiteKey,
    getAllegroSendMinPrice: state => state.allegroSendMinPrice,
    getLanguageFallbackId: state => state.languageFallbackId,
    getAnalytics: state => state.analytics
}

export const mutations = {
    SET_OPERATOR_PROFIT_DIVISION(state, operatorProfitDivision) {
        state.operatorProfitDivision = operatorProfitDivision;
    },

    SET_ECAT_PROFIT_DIVISION(state, ecatProfitDivision) {
        state.ecatProfitDivision = ecatProfitDivision;
    },

    SET_ADERLO_SHOP_ID(state, aderloShopId) {
        state.aderloShopId = aderloShopId
    },

    SET_RECAPTCHA_SITE_KEY(state, reCaptchaSiteKey) {
        state.reCaptchaSiteKey = reCaptchaSiteKey
    },

    SET_ALLEGRO_SEND_MIN_PRICE(state, allegroSendMinPrice) {
        state.allegroSendMinPrice = allegroSendMinPrice
    },

    SET_LANGUAGE_FALLBACK_ID(state, languageFallbackId) {
        state.languageFallbackId = languageFallbackId
    },

    SET_ANALYTICS(state, analytics) {
        state.analytics = analytics
    }
}

export const actions = {
    setOperatorProfitDivision({commit}, payload) {
        commit('SET_OPERATOR_PROFIT_DIVISION', payload)
    },

    setEcatProfitDivision({commit}, payload) {
        commit('SET_ECAT_PROFIT_DIVISION', payload)
    },

    setAderloShopId({commit}, payload) {
        commit("SET_ADERLO_SHOP_ID", payload)
    },

    setReCaptchaSiteKey({commit}, payload) {
        commit("SET_RECAPTCHA_SITE_KEY", payload)
    },

    setAllegroSendMinPrice({commit}, payload) {
        commit("SET_ALLEGRO_SEND_MIN_PRICE", payload)
    },

    setLanguageFallbackId({commit}, payload) {
        commit("SET_LANGUAGE_FALLBACK_ID", payload)
    },

    setAnalytics({commit}, payload) {
        commit("SET_ANALYTICS", payload)
    }
}