<template>
  <div>
    <label v-if="label">{{ label }}</label>

    <vue-multiselect
        :class="selectedValue ? 'input-selected' : ''"
        ref="multiselect"
        :value="selectedValue"
        @select="value => selectValue(value)"
        @remove="deselectValue"
        :options="viewList ? viewList : []"
        :loading="loading"
        :custom-label="value => this.customLabelText(value)"
        @search-change="value => loadElements(value)"
        @open="loadElements"
        @close="onClose"
        :placeholder="placeholder"
        :show-labels="false"
        :disabled="disabled">

      <template slot="singleLabel" slot-scope="{ option }">
        <span @mousedown.prevent.stop="deselectValue" @focus.prevent.stop="deselectValue" class="clickable-element text-danger font-size-14">
          <i class="fa fa-times" />
        </span>

        <img v-if="imageId" :src="getObj(option)[imageId]" alt="Icon" class="icon"/>
        {{ customLabelText(option) }}
      </template>

      <template v-if="imageId" slot="option" slot-scope="{ option }">
        <img :src="getObj(option)[imageId]" alt="Icon" class="icon"/>
        {{ customLabelText(option) }}
      </template>

      <span slot="noOptions">{{ $t('message.list-is-empty') }}</span>
      <span slot="noResult">Brak wyników.</span>

      <span slot="afterList">
        <infinite-loading ref="infiniteLoading" @infinite="loadMore">
          <span slot="no-more"/>
          <span slot="no-results"/>
        </infinite-loading>
      </span>
    </vue-multiselect>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ecat-multiselect",

  props: {
    multiSelectId: {
      type: String,
      // required: true,
      default: function () {
        return ""
      }
    },

    disabled: {
      type: Boolean,
      default: false
    },

    label: {
      type: String
    },

    loadUrl: {
      type: String
    },

    queryUrl: {
      type: String
    },

    fetchOneUrl: {
      type: String
    },

    setValue: {
      type: String
    },

    placeholder: {
      type: String,
      default: ""
    },

    viewId: {
      type: String
    },

    saveId: {
      type: String
    },

    canUnselect: {
      type: Boolean,
      default: false
    },

    param: {
      type: String
    },

    imageId: {
      type: String,
    },

    resetOptionName: {
      type: String
    },

    customParams: {
      type: Object,
      default: function () {
        return {}
      }
    },

    removeFromList: {
      type: Array,
      default: function () {
        return []
      }
    },

    listName: {
      type: String,
      default: null
    },

    customLabel: {
      type: Function,
      default: (option) => option
    }
  },

  watch: {
    setValue: {
      handler: async function (newValue, oldValue) {
        if (newValue !== oldValue) {
          this.selectedValue = newValue
          if (oldValue && !this.selectedValue) {
            await this.$store.dispatch("multiselect/setMultiselect", {id: this.multiSelectId, value: null})
          }
        }
      },

      immediate: true
    }
  },

  data() {
    return {

      loading: false,

      map: new Map(),
      viewList: [],
      list: new Set(),
      requestList: [],

      selectedValue: '',
      selectedObject: null,

      lazyLoading: {
        page: 1,
        loading: false
      }

    }
  },

  async mounted() {
    await this.loadValue()
  },

  methods: {
    onClose() {
      // this.$refs.infiniteLoading.stateChanger.reset()
      // this.lazyLoading.page = 1

      // this.$refs.multiselect.pointer = 0;
      // if (this.$refs.multiselect.$refs.list) {
      //   this.$refs.multiselect.$refs.list.scrollTop = 0;
      // }
    },

    async loadValue() {
      if (this.multiSelectId) {
        const ecatMultiSelect = this.$store.getters["multiselect/getMultiselect"](this.multiSelectId);
        if (ecatMultiSelect) {
          this.selectedValue = ecatMultiSelect.value
        } else {
          return
        }
      } else if (this.setValue) {
        this.selectedValue = this.setValue
      }

      if (!this.selectedValue) {
        return
      }

      await this.$emit("change", this.selectedValue)

      if (!this.fetchOneUrl) {
        return
      }

      try {
        const {data} = await axios.get(`${this.fetchOneUrl}/${this.selectedValue}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: this.customParams
        });

        if (!data) {
          this.deselectValue(true)
          return
        }

        await this.$emit("change-object", data)
        this.setData([data])
      } catch (error) {
        console.log(error)
      }
    },

    getObj(value) {
      if (!this.map) {
        return {}
      }

      const obj = this.map.get(value)
      if (!obj) {
        return {}
      }

      return obj
    },

    selectValue(value) {
      if (this.selectedValue && value === "XDDDDDDDDDDDDDDDDDDDDD") {
        this.selectedValue = ""
        this.selectedObject = null
        this.$emit("change", "")
        this.$emit("select", "")
        this.$emit("change-object", null)

        if (this.multiSelectId) {
          this.$store.dispatch("multiselect/setMultiselect", {id: this.multiSelectId, value: null})
        }

        return;
      }

      this.selectedValue = value
      this.selectedObject = this.getObj(value)

      this.$emit("change", value)
      this.$emit("select", "")
      this.$emit("change-object", this.selectedObject)

      if (this.multiSelectId) {
        this.$store.dispatch("multiselect/setMultiselect", {id: this.multiSelectId, value: value})
      }
    },

    deselectValue(instant = false) {
      if (!this.canUnselect && !instant) {
        return
      }

      this.selectedValue = ""
      this.selectedObject = null
      this.$emit("change", "")
      this.$emit("select", "")
      this.$emit("change-object", null)

      if (this.multiSelectId) {
        this.$store.dispatch("multiselect/setMultiselect", {id: this.multiSelectId, value: null})
      }
    },

    async loadElements(text) {
      this.loading = true

      if (!text) {
        await this.loadElementsByPagination()
        return
      }

      await this.loadElementsByQuery(text)
    },

    setData(resultList, update = false) {
      if (resultList && resultList.length > 0) {
        let list = this.list
        // let list = this.list.filter(element => {
        //   return resultList.some(resultElement => {
        //     return (this.saveId ? element[this.saveId] : element) === (this.saveId ? resultElement[this.saveId] : resultElement)
        //   });
        // });

        // this.map = this.map

        if (/*this.selectedValue && */this.resetOptionName && !this.list.has("XDDDDDDDDDDDDDDDDDDDDD")) {
          this.map.set("XDDDDDDDDDDDDDDDDDDDDD", this.viewId ? {[this.viewId]: this.resetOptionName} : this.resetOptionName)
          list.add("XDDDDDDDDDDDDDDDDDDDDD")
        }

        for (const element of resultList) {
          list.add(this.saveId ? element[this.saveId] : element)
          this.map.set(this.saveId ? element[this.saveId] : element, element)
        }

        // new Map(resultList.map((obj) => [this.saveId ? obj[this.saveId] : obj, obj]));

        for (const data of this.removeFromList) {
          try {
            list = list.filter(element => element !== data)
            this.map.delete(data)
          } catch (error) {
            // ignored
          }
        }

        this.list = list
        this.requestList = resultList
      } else {
        this.map = new Map()
        this.list = []
      }

      if (this.selectedValue && !this.list.has(this.selectedValue)) {
        this.list.add(this.selectedValue)
        this.map.set(this.selectedValue, this.selectedObject)
      }

      this.viewList = [...this.list]
      this.loading = false

      if (update && this.selectedValue) {
        this.selectValue(this.selectedValue)
      }
    },

    async loadElementsByQuery(text) {
      if (!this.queryUrl) {
        return
      }

      this.lazyLoading.page = 1

      try {
        let params = {...this.customParams};
        params[this.param] = text

        const {data} = await axios.get(`${this.queryUrl}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: params
        });

        if (Array.isArray(data) && data.length === 0) {
          return
        } else if (!data) {
          return
        }

        this.setData(Array.isArray(data) ? data : [data])
      } catch (error) {
        // ignored
      }
    },

    async loadElementsByPagination() {
      try {
        const {data} = await axios.get(`${this.loadUrl}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            page: 0,
            size: 10,
            ...this.customParams
          }
        });

        if (Array.isArray(data) && data.length === 0) {
          return
        } else if (!data) {
          return
        }

        this.setData(this.listName ? data[this.listName] : Array.isArray(data) ? data : [data])
      } catch (error) {
        // ignored
      }
    },

    loadMore($state) {
      const perPage = 5;
      let page = this.lazyLoading.page - 1;
      if (page > 0) {
        page = this.lazyLoading.page * perPage - perPage;
      }

      axios.get(`${this.loadUrl}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
        data: {},
        params: {
          "page": page,
          "size": perPage,
          ...this.customParams
        },
      }).then(({data}) => {
        if (Array.isArray(data) && data.length === 0) {
          $state.complete();
          return
        } else if (!data) {
          $state.complete();
          return
        }

        const list = this.listName ? data[this.listName] : Array.isArray(data) ? data : [data]

        if (list && list.length) {
          this.lazyLoading.page += 1;

          const oldList = [...this.requestList]

          for (const newElement of list) {
            if (!oldList.find(element => {
              if (this.saveId) {
                return element[this.saveId] === newElement[this.saveId]
              }

              element === newElement
            })) {
              oldList.push(newElement)
            }
          }

          this.setData(oldList)
          $state.loaded();
        } else {
          $state.complete();
        }
      }).catch(() => {
        $state.complete();
      });
    },

    customLabelText(option) {
      if (!this.map) {
        return ""
      }

      const obj = this.getObj(option)
      if (!obj) {
        return ""
      }

      const text = this.customLabel(this.viewId ? obj[this.viewId] : obj);
      if (!text) {
        return ""
      }

      return text
    }

  }

}
</script>

<style scoped>
.icon {
  max-width: 24px;
  max-height: 24px;
}
</style>