<template>
  <div class="py-2">
    <div class="row" v-if="options">
      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_length" class="dataTables_length">
          <label class="d-inline-flex align-items-center">
            {{ $t('table.show') }}&nbsp;
            <b-form-select
                v-model="table.perPage"
                size="sm"
                :options="table.pageOptions"
                @loaded="setToFirstPageAndRefresh"
            />
            &nbsp;{{
              $t('table.entries')
            }}
          </label>
        </div>
      </div>

      <div class="col-sm-12 col-md-6">
        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
          <label class="d-inline-flex align-items-center">
            {{ $t('table.search') }}
            <b-form-input
                v-model="table.filter"
                class="form-control form-control-sm ml-2"
                type="search"
                @keyup.enter="setToFirstPageAndRefresh"
                @input="handleSearch"
            />
          </label>
        </div>
      </div>
    </div>

    <div class="row mt-3" v-if="options">
      <div class="col-sm-12 col-sm-6">
        <slot name="leftBox"/>
      </div>

      <div v-if="paginationTop" class="col-sm-12 col-sm-6 d-inline-flex justify-content-end">
        <div class="dataTables_paginate paging_simple_numbers">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"/>
          </ul>
        </div>
        <div class="align-items-center">
          <label class="d-inline-flex align-items-center mx-2 mb-0">
            <b-form-input v-model.number="table.inputPage" type="number" class="form-control form-control-sm ml-2 form-xs"/>
          </label>
          <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">{{ $t('table.go-to-page') }}
          </b-button>
        </div>
      </div>
    </div>

    <div class="table-responsive my-3">
      <template v-if="loading">
        <div class="text-center">
          <h5>{{ $t('message.loading') }}</h5>
          <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
        </div>
      </template>
      <template v-else-if="customTable">
        <template v-if="!table.items || table.items.length === 0">
          <div class="col-lg-12 text-center product-body py-3 mb-2">
            Brak danych
          </div>
        </template>
        <template v-else>
          <div v-for="(field, index) in fields" :key="`field-${index}`" class="col-xl-12 mb-2 rounded-5">
            {{ $t(field.label) }}
          </div>

          <div v-for="(item, index) in table.items" :key="`item-${index}`">
            <div v-for="(field, index) in fields" :key="`field-in-item-${index}`" class="col-xl-12 mb-2 rounded-5">
              {{ getValue(item, field) }}
            </div>
          </div>
        </template>
      </template>
      <template v-else>
        <b-table
            :class="centered ? 'text-center' : ''"
            ref="table"
            :current-page="table.currentPage"
            :fields="fields"
            :filter="table.filter"
            :filter-included-fields="table.filterOn"
            :items="loadItems"
            :per-page="table.perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="table.sortDesc"
            responsive="sm"
            :empty-text="$t('message.no-elements')"
            :empty-filtered-text="$t('message.no-records')"
            :show-empty="true"
            :busy.sync="internalTable.isBusy">
          <template v-for="field in filteredFields()" v-slot:[`cell(${field.key})`]="slotProps">
            <slot :name="field.key" :item="slotProps.item"/>
          </template>

          <div slot="table-busy" class="text-center">
            <h5>{{ $t('message.loading') }}</h5>
            <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
          </div>
        </b-table>
      </template>
    </div>

    <div class="row" v-if="options">
      <div class="col">
        <div class="float-left">
          {{
            $t('table.entries-footer', {
              'amount': paginationHelper.getElementsAmount(table),
              'elements': paginationHelper.getElementsCount(table),
              'all': table.totalRows
            })
          }}
        </div>
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded mb-0">
            <b-pagination
                v-model="table.currentPage"
                :total-rows="table.rows"
                :per-page="table.perPage"
            />
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {paginationHelper} from "@/helpers/pagination-helper";
import {debounce} from "vue2-leaflet/dist/vue2-leaflet.min";

export default {
  name: "ecat-table",

  computed: {
    paginationHelper() {
      return paginationHelper
    },

    sortBy: {
      get() {
        return this.table.sortBy;
      },

      set(value) {
        for (const field of this.fields) {
          if (field.key === value && field.dbKey) {
            this.table.dbSortBy = field.dbKey
          }
        }

        this.table.sortBy = value
      }
    }
  },

  data() {
    return {
      loading: false,

      internalTable: {
        busy: false
      }
    }
  },

  props: {
    items: {
      validator: function (value) {
        return Array.isArray(value) || value instanceof Function;
      },

      required: true
    },

    paginationTop: {
      type: Boolean,
      default: false
    },

    options: {
      type: Boolean,
      default: true
    },

    fields: {
      type: Array,
      required: true
    },

    customTable: {
      type: Boolean,
      default: false
    },

    table: {
      type: Object,
      required: true
    },

    centered: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleSearch: debounce(function() {
      this.setToFirstPageAndRefresh()
    }, 250),

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, "table")
    },

    async loadItems() {
      if (this.customTable) {
        this.loading = true
      }

      if (Array.isArray(this.items)) {
        this.table.totalRows = this.items.length
        this.table.rows = this.items.length
        return this.table.items = this.items
      }

      try {
        await this.items()
      } catch (error) {
        if (this.customTable) {
          this.loading = false
        }

        this.table.totalRows = 0
        this.table.rows = 0
        this.table.items = []
      } finally {
        if (this.customTable) {
          this.loading = false
        }
      }

      if (this.customTable) {
        this.loading = false
      }

      return this.table.items
    },

    getValue(item, field) {
      if (!field.key) {
        return ""
      }

      return item[field.key] || ""
    },

    filteredFields() {
      return this.fields.filter(field => field.slot)
    },

    refresh() {
      this.setToFirstPageAndRefresh()
    }
  },

  async created() {
    if (!this.customTable) {
      return
    }

    await this.loadItems()
  }

}
</script>